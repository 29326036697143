import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCarousel,{ref:"itemCarousel",staticClass:"album-item-viewer",attrs:{"hide-delimiters":"","max-width":"80vw","max-height":"80vh"},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var prevAttr = ref.attr;
var prevOn = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"white"}},'v-btn',prevAttr,false),prevOn),[_c(VIcon,{attrs:{"size":"36"}},[_vm._v("mdi-chevron-left")])],1)]}},{key:"next",fn:function(ref){
var nextAttr = ref.attr;
var nextOn = ref.on;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"white","disabled":_vm.isLoading}},'v-btn',nextAttr,false),nextOn),[(_vm.isLoading)?_c(VProgressCircular,{attrs:{"width":3,"size":26,"color":"white","indeterminate":""}}):_c(VIcon,{attrs:{"size":"36"}},[_vm._v("mdi-chevron-right")])],1)]}}]),model:{value:(_vm.showingItemIdx),callback:function ($$v) {_vm.showingItemIdx=$$v},expression:"showingItemIdx"}},[_vm._l((_vm.items),function(item,i){return _c(VCarouselItem,{key:item.id || item.content},[(item.type === 'IMAGE')?_c(VImg,{attrs:{"src":'/api/' + item.content,"contain":"","width":"100%","height":"100%"},on:{"load":function($event){return _vm.itemViewed(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}):_vm._e(),(item.type === 'VIDEO')?_c('video',{attrs:{"controls":"","width":"100%","height":"100%","autoplay":!_vm.slideshowOn},on:{"play":function($event){return _vm.itemViewed(item)}}},[_c('source',{attrs:{"src":'/api/' + item.content}})]):_vm._e(),(item.caption)?_c('div',{staticClass:"album-item-caption"},[_vm._v(_vm._s(item.caption)+" ")]):_vm._e()],1)}),(_vm.getCurrentItem.exif && _vm.getCurrentItem.exif !== '{}')?_c('div',{staticClass:"album-item-exif-wrapper"},[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"album-item-exif-btn",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-information-outline")])],1)]}}],null,false,3765178711)},[_c('pre',{staticClass:"album-item-exif-div",domProps:{"innerHTML":_vm._s(_vm.getExif(_vm.getCurrentItem.exif))}})]),_c('div',{staticClass:"mt-2"},[_c(VBtn,{staticClass:"dialog-close-btn",attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1):_vm._e(),_c('div',{staticClass:"album-item-slideshow-div"},[_c('div',[_c(VBtn,{class:['album-item-slideshow-btn', {'enabled': _vm.slideshowOn}],attrs:{"icon":"","x-large":""},on:{"click":function($event){_vm.slideshowOn = !_vm.slideshowOn}}},[_c(VIcon,{attrs:{"color":_vm.slideshowOn? 'white' : 'grey'}},[_vm._v("mdi-update")])],1)],1),_c('div',{staticClass:"mt-2"},[_c(VBtn,{staticClass:"album-item-slideshow-btn",attrs:{"icon":""},on:{"click":_vm.decreaseSlideShowInterval}},[_c(VIcon,[_vm._v("mdi-minus")])],1),_c('span',{staticClass:"px-4"},[_vm._v(_vm._s(_vm.slideShowInterval)+"s")]),_c(VBtn,{staticClass:"album-item-slideshow-btn",attrs:{"icon":""},on:{"click":_vm.increaseSlideShowInterval}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }