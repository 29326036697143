import Vue from 'vue'

// Composition API
import VueCompositionApi from '@vue/composition-api';

Vue.use(VueCompositionApi);

// VueResource
import VueResource from 'vue-resource';

Vue.use(VueResource);

// i18n
import i18n from './plugins/i18n'

// vuetify-dialog
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

//V-Viewer
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

Vue.use(Viewer);

Vue.use(VuetifyDialog, {
    context: {
        i18n,
        vuetify,
        store,
    },
    confirm: {
        showClose: false,
    },
});

//
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

Vue.use(VueViewer);

import VueMobileDetection from "vue-mobile-detection";
Vue.use(VueMobileDetection);

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {DateTime} from "luxon";
import {ACTION_TYPES} from "@/store/types";

Vue.config.productionTip = false

Vue.use({
    install() {
        Vue.prototype.$isTeamNote = function () {
            return Boolean(window.tnConnector);
        };
        
        Vue.prototype.$loginTeamNote = async function (path) {
            const response = await store.dispatch(ACTION_TYPES.CALL_API, {
                url: 'oauth/',
            });
            const host = response.body.host;
            const clientId = response.body.client_id;
            const preferredMethod = response.body.preferred_method;


            setTimeout(() => {
                let afterLogin = window.location.origin + window.location.pathname + (path ? `#${path}` : window.location.hash);
                
                if (!window.tnConnector) {
                    window.location.href = `${host}/oauth2/auth?response_type=id_token&client_id=${clientId}&scope=user_name&redirect_uri=${encodeURIComponent(afterLogin)}&prefer=${encodeURIComponent(preferredMethod)}`;
                } else {
                    window.location.href = `${host}/oauth2/auth?response_type=id_token&client_id=${clientId}&scope=user_name&redirect_uri=${encodeURIComponent(afterLogin)}`;
                }
            }, 50);
        };

        Vue.prototype.$parseDate = function (d, fmt = 'yyyy-MM-dd HH:mm') {
            return DateTime.fromISO(d).toFormat(fmt);
        };

        Vue.prototype.$showImage = function (image) {
            this.$viewerApi({
                options: {
                    inline: false,
                    title: false,
                    toolbar: false,
                    rotatable: false,
                    navbar: false,
                    transition: false,
                },
                images: [{
                    thumbnail: '/api/file/' + image.original,
                    source: '/api/file/' + image.original,
                    alt: image.caption,
                }],
            })
        }

        Vue.prototype.$downloadImage = async function (image) {
            let path = `file/${image.content}`;

            let response = await this.$store.dispatch(ACTION_TYPES.CALL_API, {
                url: path,
                params: {},
                opt: {extra: {responseType: 'arraybuffer'}},
            });

            let blob = new Blob([response.data], {type: response.headers.get('content-type')});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = decodeURI(response.headers.get('content-filename'));
            link.click();
        }
    },
})

setTimeout(async () => {
    if (window.tnConnector) {
        window.tnConnector.init({
            featureId: 'booking',
        }, () => {
            // window.tnConnector.ui.setNavigationBarColor({
            //     uiColor: '#e24139',
            // })
            window.tnConnector.ui.setNavigationBarVisibility({visibility: 0});

            initVue();
        }, (error) => {
            console.log(error);
        });
    } else {
        initVue();
    }

    if (window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations()
            .then(function (registrations) {
                for (let registration of registrations) {
                    registration.unregister();
                }
            });
    }
}, 50);

function initVue() {
    new Vue({
        router,
        store,
        i18n,
        vuetify,
        render: h => h(App)
    }).$mount('#app')
}

