<template>
  <v-container fluid class="album-view pa-0" v-if="album">
    <div class="album-breadcrumb" :style="{ height: !$isMobile() ? '24px' : '36px'}">
      <v-btn text x-small color="primary" exact :to="{ name: 'album-list' }">
        &lt;&lt; {{ $t('album.title') }}
      </v-btn>
      <v-menu offset-y v-if="$isMobile()">
        <template #activator="{on, attrs}">
          <v-btn icon
                  class="album-item-exif-btn"
                  v-bind="attrs"
                  v-on="on">
            <v-icon color="grey">mdi-information-outline</v-icon>
          </v-btn>
        </template>
        <div class="album-info-menu">
          <div class="album-title">
            {{ album.title }}
          </div>
          <div class="album-owners"
            v-if="albumOwners">Owner(s): {{ albumOwners }}
          </div>
          <div class="album-create-date">
            Create Date: {{ $parseDate(album.created_at, 'dd/MM/yyyy') }}
          </div>
          <div class="album-create-date">
            Last Update: {{ $parseDate(album.updated_at, 'dd/MM/yyyy') }}
          </div>
          <template v-if="isInRootFolder">
            <div class="album-create-date">
              <!-- Total Image Count: {{ totalImageCount }} -->
              Total Image Count: {{ album.media_count }}
            </div>
            <div class="album-create-date">
              <!-- Total Sub-album Count: {{ totalSubFolderCount }} -->
              Total Sub-album Count: {{ album.folder_count }}
            </div>
          </template>
        </div>
      </v-menu>
    </div>
    <div v-if="!$isMobile()">
      <div class="album-title">
        {{ album.title }}
      </div>
      <div class="album-owners"
          v-if="albumOwners">Owner(s): {{ albumOwners }}
      </div>
      <div class="album-create-date">
        Create Date: {{ $parseDate(album.created_at, 'dd/MM/yyyy') }}
      </div>
      <div class="album-create-date">
        Last Update: {{ $parseDate(album.updated_at, 'dd/MM/yyyy') }}
      </div>
      <template v-if="isInRootFolder">
        <div class="album-create-date">
          <!-- Total Image Count: {{ totalImageCount }} -->
          Total Image Count: {{ album.media_count }}
        </div>
        <div class="album-create-date">
          <!-- Total Sub-album Count: {{ totalSubFolderCount }} -->
          Total Sub-album Count: {{ album.folder_count }}
        </div>
      </template>
      <div class="album-divider my-4"></div>
    </div>
    <AlbumItemList
      v-if="album"
      :album-id="album.id"
      :album-media-count="album.media_count"
      :album-folder-count="album.folder_count"
      @update-total-count="updateTotalCount"
      @update-is-root-folder="checkIfInRootFolder"
    ></AlbumItemList>
  </v-container>
</template>
<script>
import {computed, onMounted, ref} from "@vue/composition-api";
import {ACTION_TYPES} from "@/store/types";
import AlbumItemList from "@/components/AlbumItemList.vue";

export default {
  name: 'AlbumView',
  components: {AlbumItemList},
  setup(props, {root}) {
    const album = ref(null);
    const albumOwners = computed(() => {
      if (album.value) {
        return album.value.admins.map((a) => a.name).join(", ");
      }
      return null;
    })
    const totalImageCount = ref(0);
    const totalSubFolderCount = ref(0);
    const updateTotalCount = function({image_count, folder_count}) {
      totalImageCount.value = image_count
      totalSubFolderCount.value = folder_count
    }
    const isInRootFolder = ref(true);
    const checkIfInRootFolder = function (isRoot) {
      isInRootFolder.value = isRoot === null;
    }
    const getAlbum = async function (not_blocking = false) {
      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'album/',
        params: {
          album_id: root.$route.params.albumId,
        },
        opt: {not_blocking}
      });

      if (response.body.success) {
        album.value = response.body.album;

        // await getPhotos(not_blocking)
      }
    };
    const downloadAlbum = async function () {
      let response = await this.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'album/download/',
        params: {
          album_id: album.value.id,
        },
        opt: {extra: {responseType: 'arraybuffer'}},
      });

      let blob = new Blob([response.data], {type: response.headers.get('content-type')});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${album.value.title}.zip`;
      link.click();
    }

    onMounted(() => {
      getAlbum()
    })

    return {
      album,
      albumOwners,

      totalImageCount,
      totalSubFolderCount,
      updateTotalCount,

      isInRootFolder,
      checkIfInRootFolder,
      
      downloadAlbum,
    }
  },
}
</script>

<style lang="less">
.album-view {
  height: 100%;
  display: flex;
  flex-direction: column;

  .album-breadcrumb {
    display: flex;
    align-items: center;
    // justify-content: space-between;
  }

  .album-owners, .album-create-date {
    opacity: 0.5;
    font-size: 80%;
  }

  .album-divider {
    border-bottom: solid 1px #ECECEE;
  }
}

.album-title {
  font-weight: bold;
  font-size: 150%;
}

.album-info-menu {
  overflow: auto;
  max-height: 50vh;
  padding: 16px;
  background-color: white;
  color: black;
  max-width: 50vw;
}
</style>
