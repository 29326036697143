import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '../assets/fontawesome.all.min';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export const PRIMARY_COLOR = '#a4000e';

export default new Vuetify({
    theme: {
        options: {customProperties: true},
        themes: {
            light: {
                primary: PRIMARY_COLOR,
                secondary: '#7D6C6C',
                accent: '#5bc0de',
                error: '#ff5252',
                warning: '#ffeb3b',
                info: '#00bcd4',
                success: '#009688',
            },
        },
    },
});
