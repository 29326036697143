<template>
  <v-carousel v-model="showingItemIdx"
              class="album-item-viewer"
              hide-delimiters
              max-width="80vw"
              max-height="80vh"
              ref="itemCarousel">
    <v-carousel-item v-for="(item, i) in items" :key="item.id || item.content">
      <v-img v-if="item.type === 'IMAGE'"
             :src="'/api/' + item.content"
             contain
             width="100%"
             height="100%"
             @load="itemViewed(item)">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
      </v-img>
      <video v-if="item.type === 'VIDEO'"
             controls
             width="100%"
             height="100%"
             :autoplay="!slideshowOn"
             @play="itemViewed(item)">
        <source :src="'/api/' + item.content">
      </video>

      <div class="album-item-caption"
           v-if="item.caption">{{ item.caption }}
      </div>
    </v-carousel-item>

    <div class="album-item-exif-wrapper"
           v-if="getCurrentItem.exif && getCurrentItem.exif !== '{}'">
        <v-menu offset-y>
          <template #activator="{on, attrs}">
            <v-btn icon
                   class="album-item-exif-btn"
                   v-bind="attrs"
                   v-on="on">
              <v-icon color="white">mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <pre class="album-item-exif-div" v-html="getExif(getCurrentItem.exif)"></pre>
        </v-menu>

        <div class="mt-2">
          <v-btn icon
                class="dialog-close-btn"
                @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>

    <div class="album-item-slideshow-div">
      <div>
        <v-btn icon
               x-large
               :class="['album-item-slideshow-btn', {'enabled': slideshowOn}]"
               @click="slideshowOn = !slideshowOn">
          <v-icon :color="slideshowOn? 'white' : 'grey'">mdi-update</v-icon>
        </v-btn>
      </div>
      <div class="mt-2">
        <v-btn icon
               class="album-item-slideshow-btn"
               @click="decreaseSlideShowInterval">
          <v-icon>mdi-minus</v-icon>
        </v-btn>
        <span class="px-4">{{ slideShowInterval }}s</span>
        <v-btn icon
               class="album-item-slideshow-btn"
               @click="increaseSlideShowInterval">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <!-- <div class="mt-2">
        <v-btn icon
               class="album-item-slideshow-btn"
               @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div> -->
    </div>

    <template #prev="{ attr: prevAttr, on: prevOn }">
      <v-btn
        icon
        color="white"
        v-bind="prevAttr"
        v-on="prevOn"
      >
        <v-icon size="36">mdi-chevron-left</v-icon>
      </v-btn>
    </template>

    <template #next="{ attr: nextAttr, on: nextOn }">
      <v-btn
        icon
        color="white"
        v-bind="nextAttr"
        v-on="nextOn"
        :disabled="isLoading"
      >
        <v-progress-circular
          :width="3"
          :size="26"
          color="white"
          indeterminate
          v-if="isLoading"
        ></v-progress-circular>
        <v-icon size="36" v-else>mdi-chevron-right</v-icon>
      </v-btn>
    </template>

  </v-carousel>
</template>

<script>
import {onBeforeUnmount, onMounted, ref, watch, toRef, computed} from "@vue/composition-api";
import {ACTION_TYPES} from "@/store/types";
import _ from "lodash";
import {prettyPrintJson} from "pretty-print-json";

export default {
  name: 'AlbumItemViewer',
  props: {
    viewingItemId: String,
    albumId: String,
    parentId: String,
    albumItems: Array,
    allItemLoaded: Boolean,
    isLoadingItem: Boolean,
  },
  setup(props, {root, emit}) {
    const showingItemIdx = ref(0);
    const items = ref(_.cloneDeep(props.albumItems) || []);
    const itemCarousel = ref(null);

    watch(() => props.albumItems, (val) => {
      // console.log('props.albumItems', val)
      items.value = _.cloneDeep(val);

      if (!props.allItemLoaded && !props.isLoadingItem) {
        if (showingItemIdx.value === items.value.length - 1) {
          console.log('get next page album items when no new image/video items') 
          emit('update-album-items');
        }
      }
    }, { deep: true });

    // watch(() => props.isLoadingItem, (val) => {
    //   isLoading.value = val;
    // });

    watch(() => showingItemIdx.value, (newVal, oldVal) => {
      if (props.allItemLoaded) {
        return;
      }

      if (props.isLoadingItem) {
        return;
      }

      if (newVal !== oldVal) {
        const differ = items.value.length - newVal;
        if (differ <= 10) {
          emit('update-album-items');

          // if (differ === 0) {
          //   slideshowOn.value = false;
          //   clearInterval(slideShow);
          // }
        }
      }
    }, { immediate: true });

    const isLoading = computed(() => {
      return props.isLoadingItem && showingItemIdx.value === items.value.length - 1;
    })

    const getCurrentItem = computed(() => {
      return items.value[showingItemIdx.value] || {};
    })

    const getItems = async function () {
      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'album/item/',
        params: {
          filters: {
            'album.id': props.albumId,
            'parent.id': props.parentId ? props.parentId : false,
            'type': ['IMAGE', 'VIDEO'],
          },
          fields : ['content', 'id', 'type'], // content: for image preview, id: for udpate view_no
          page_size: -1,
        },
      });

      if (response.body.success) {
        items.value = response.body.items;
        // items.value = response.body.items.map((i) => {
        //   let exif = null;
        //   try {
        //     let d = JSON.parse(i.exif);
        //     let values = [];
        //
        //     if (d['Image']) {
        //       Object.keys(d['Image']).forEach((k) => {
        //         values.push(`${k}: ${d['Image'][k]}`);
        //       })
        //     }
        //     if (d['EXIF']) {
        //       Object.keys(d['EXIF']).forEach((k) => {
        //         values.push(`${k}: ${d['EXIF'][k]}`);
        //       })
        //     }
        //
        //     exif = values.sort().join("<br>") || null;
        //   } catch (e) {
        //     ''
        //   }
        //
        //   return {
        //     ...i,
        //     exif: exif,
        //   }
        // });

        showingItemIdx.value = _.findIndex(items.value, {id: props.viewingItemId});
      }
    }

    const closeDialog = function () {
      emit('on-close-dialog')
    }

    const slideshowOn = ref(false);
    const slideShowInterval = ref(5);
    let slideShow = null;
    const startSlideShow = function () {
      if (slideShow) {
        clearInterval(slideShow);
        slideShow = null;
      }
      slideShow = setInterval(() => {
        if (slideshowOn.value) {
          if (showingItemIdx.value === items.value.length - 1) {
            if (isLoading.value) {
              return;
            }
          }

          showingItemIdx.value = (showingItemIdx.value + 1) % items.value.length;
        }
      }, slideShowInterval.value * 1000)
    }

    const decreaseSlideShowInterval = function () {
      slideShowInterval.value = Math.max(1, slideShowInterval.value - 1);
      startSlideShow();
    }
    const increaseSlideShowInterval = function () {
      slideShowInterval.value = Math.min(30, slideShowInterval.value + 1);
      startSlideShow();
    }

    const getExif = function (exif) {
      return prettyPrintJson.toHtml(JSON.parse(exif));
    }

    const itemViewed = async function (item) {
      console.log('itemViewed', item.id);
      root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'view/',
        params: {
          id: item.id,
        },
        opt: {not_blocking: true},
      });
    }

    const handleKeydown = (event) => {
      if (event.key === "ArrowLeft") {
        itemCarousel.value?.prev(); 
      } else if (event.key === "ArrowRight") {
        if (isLoading.value) {
          return;
        }
        
        itemCarousel.value?.next();
      }
    };

    onMounted(() => {
      window.addEventListener("keydown", handleKeydown);
      showingItemIdx.value = _.findIndex(items.value, {id: props.viewingItemId});
      // getItems();


      startSlideShow();
    })

    onBeforeUnmount(() => {
      window.removeEventListener("keydown", handleKeydown);
      clearInterval(slideShow);
    })

    return {
      showingItemIdx,
      items,
      slideshowOn,
      slideShowInterval,
      decreaseSlideShowInterval,
      increaseSlideShowInterval,
      getExif,
      itemViewed,

      itemCarousel,
      closeDialog,

      isLoading,
      getCurrentItem
    }
  },
}
</script>

<style lang="less">
.v-dialog {
  box-shadow: none !important;
}

.album-item-exif-div {
  overflow: auto;
  max-height: 50vh;
  padding: 24px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  max-width: 50vw;
}

.album-item-viewer {
  //background: white;

  .album-item-exif-wrapper {
    position: fixed;
    top: 12px;
    left: 12px;
    text-align: right;
    color: white;

    display: flex;
    flex-direction: column;

    .album-item-exif-btn {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .dialog-close-btn {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .album-item-slideshow-div {
    position: fixed;
    top: 12px;
    right: 12px;
    text-align: right;
    color: white;

    .album-item-slideshow-btn {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .album-item-caption {
    position: fixed;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
  }
}

.vuedl-layout__closeBtn {
  font-size: 60px;
  width: 25px;
  height: 25px;
  color: #000000;
}
</style>
