<template>
  <v-dialog v-model="dialogShown"
            persistent
            fullscreen
            content-class="login-dialog"
            transition="fade-transition">
    <v-container class="login">
      <v-row>
        <v-col>
          <v-img src="../assets/img.png"
                 height="10vh"
                 max-height="200px"
                 contain></v-img>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col class="text-h5 text-center">
          {{ $t('title') }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-text-field outlined
                        :label="$t('login.username')"
                        v-model="username"></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-text-field outlined
                        :label="$t('login.password')"
                        v-model="password"
                        type="password"
                        @keyup.enter="login"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn block
                 color="primary"
                 dark
                 @click="login">
            {{ $t('login.title') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn block
                 color="accent"
                 dark
                 height="60px"
                 class="login-btn"
                 @click="$loginTeamNote()">
            <div class="login-sso-text">
              {{ $t('login.login_with_teamnote') }}
            </div>
            <div class="staff-only-text">
              {{ $t('login.staff_only') }}
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <!-- login failed snackbar -->
    <v-snackbar
      class="login-snackbar"
      content-class="snackbar-content"
      v-model="showSnackbar"
      :timeout="3000"
      absolute
      outlined
      color="primary"
    >
      <v-icon left color="primary">mdi-alert-circle-outline</v-icon>
      {{ $t('login.login_failed_message') }}      
    </v-snackbar>
  </v-dialog>
</template>

<script>
import {onMounted, ref, watch} from '@vue/composition-api';
import {ACTION_TYPES} from "@/store/types";

export default {
  name: 'LoginModal',
  props: {
    shown: Boolean,
  },
  setup(props, {root}) {
    const dialogShown = ref(props.shown);
    watch(() => props.shown, (newValue, oldValue) => {
      if (newValue && !oldValue) {
        username.value = '';
        password.value = '';
      }

      dialogShown.value = newValue;
    });

    const username = ref('');
    const password = ref('');
    const login = async function () {
      showSnackbar.value = false;

      try {
        await root.$store.dispatch(ACTION_TYPES.LOGIN, {
          username: username.value,
          password: password.value,
        });
        
        // first login in 
        root.$store.commit('setIsFirstLoggedIn')
        
      } catch (error) {
        showSnackbar.value = true
      }
      
      password.value = '';
    };

    // show/hide the login failed message snackbar
    let showSnackbar = ref(false)

    return {
      dialogShown,
      username,
      password,
      login,

      showSnackbar
    }
  }
}
</script>

<style lang="less">
.login-dialog {
  background-color: white;
  background-image: url('../assets/background.png');
  background-size: cover;
  background-position: top;

  .login {
    max-width: 750px;

    .login-btn {
      text-transform: none;
      
      .v-btn__content {
        display: block;
      }

      .login-sso-text {
        font-size: 15px;
      }

      .staff-only-text {
        font-size: 22px;
      }
    }

    .login-footer {
      font-size: 60%;
    }
  }

  .login-snackbar {
    bottom: 100px;
    
    div.v-snack__action {
      display: none;
    }

    div.v-snack__wrapper {
      background-color: transparent;
    }
  }

  .snackbar-content {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }
}
</style>
